import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/base.css'

import { NavBar, Swipe, SwipeItem, Rate } from 'vant'
// import waterfall from 'vue-waterfall2'
// Vue.use(waterfall)
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Rate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const setRemUnit = () => {
  const docEl = document.documentElement
  const rem = docEl.clientWidth / 7.5
  docEl.style.fontSize = `${rem}px`
}
const pageShowCallback = (e) => {
  if (e.persisted) {
    setRemUnit()
  }
}
document.addEventListener('DOMContentLoaded', setRemUnit, false)
window.addEventListener('resize', setRemUnit, false)
window.addEventListener('pageshow', pageShowCallback, false)
export default setRemUnit
