<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
      <router-view />
    </keep-alive>
    <!-- <div
      v-if="$route.path !== '/mapHome'"
      :style="{ marginBottom: `${$route.path === '/login' ? '0' : '50px'}` }"
      class="web-info"
    >
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
.web-info {
  height: 30px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: #ccc;
  vertical-align: top;
  background-color: #f6f8fa;
}
</style>
