import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: () => import('../views/404')
  },
  {
    path: '/',
    redirect: '/mapHome'
  },
  {
    path: '/mapHome',
    name: 'Home',
    meta: { title: '北京智慧旅游地图' },
    component: () => import('../views/maps/v2.vue')
  },
  {
    path: '/mapLine/:id',
    name: 'mapLine',
    meta: { title: '线路推荐' },
    component: () => import('../views/maps/mapLine.vue')
  },
  {
    path: '/mapShow/:id',
    name: 'mapShow',
    meta: { title: '地图详情' },
    component: () => import('../views/maps/mapShow.vue')
  },
  {
    path: '/mapOrder/:id',
    name: 'mapOrder',
    meta: { title: '关联预订' },
    component: () => import('../views/maps/mapOrder.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: { title: '搜索' },
    component: () => import('../views/search/search.vue')
  },
  {
    path: '/searchList/:keyWord',
    name: 'searchList',
    meta: { title: '搜索结果' },
    component: () => import('../views/search/searchList.vue')

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, form, next) => {
  if (to.query.projectId) {
    store.state.projectId = to.query.projectId
    next()
  } else {
    const state = store.state.projectId
    const query = { projectId: state, ...to.query }
    next({
      path: to.path,
      query
    })
  }
  // const title = to.meta.title
  // document.title = title
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router

