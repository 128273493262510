import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    projectId: 10074,
    openStart: true,
    map: '',
    location: '',
    showMarker: false,
    showPopup: false,
    iframeUrl: '',
    colunmId: ''
  },
  mutations: {
    CHANGELOCATION(state, value) {
      state.location = {
        lng: value.lng,
        lat: value.lat
      }
    },
    CHECKLOGIN(state, isLogin) {
      state.isLogin = isLogin
    },
    CHANGEPROJECTID(state, projectId) {
      state.projectId = projectId
    }
  },
  actions: {
  }
})
